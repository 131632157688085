import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../../../components/layout";
import ProjectHeader from "../../../components/layouts/ProjectHeader";
import { TechnologySection, TechEntry } from "../../../sections/Project-Page/TechnologySection";
import { BreakdownSection, BreakdownGrid } from "../../../sections/Project-Page/BreakdownSection";

const WildlifeReserveCMS = () => {

    const data = useStaticQuery(
        graphql`
            query {
                mobileImage: file(relativePath: { eq: "wildlife-reserve1.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 490, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                desktopImage: file(relativePath: { eq: "wildlife-reserve1.jpg" }) {
                    childImageSharp {
                        fluid(quality: 100, maxWidth: 4160) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                gallery1: file(relativePath: { eq: "wildlife-reserve1.jpg" }) {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                          ...GatsbyImageSharpFluid
                      }
                    }
                }
                gallery2: file(relativePath: { eq: "wildlife-reserve2.jpg" }) {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                          ...GatsbyImageSharpFluid
                      }
                    }
                }
                gallery3: file(relativePath: { eq: "wildlife-reserve3.jpg" }) {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                          ...GatsbyImageSharpFluid
                      }
                    }
                }
            }
        `
    )
    
    const sourcesHeader = [
        data.mobileImage.childImageSharp.fluid,
        {
          ...data.desktopImage.childImageSharp.fluid,
          media: `(min-width: 491px)`,
        },
    ]

    const galleryImages = [
        data.gallery1.childImageSharp.fluid.src,
        data.gallery2.childImageSharp.fluid.src,
        data.gallery3.childImageSharp.fluid.src
    ]

    const headerContent = {
        github_link: "https://github.com/KyleRobertsIO/Wildlife-Reserve-MVC",
        website_link: null,
        sources: sourcesHeader,
        headline: "Wildlife Reserve CMS",
        copy: "A CMS that helps display information about difference creatures inside wildlife reserves.",
        galleryImages: galleryImages
    }

    const TechUsed = [
        "C#",
        "JavaScript",
        "MySQL"
    ]

    const TechEntries = TechUsed.map((techName) => (
        <TechEntry key={techName} TechItem={techName} />
    ))
    
    return (
        <Layout>
            <ProjectHeader content={headerContent} />
            <TechnologySection>
                {TechEntries}
            </TechnologySection>

            <BreakdownGrid>

                <BreakdownSection title="Details">
                    <p>
                        This was my final project for my C# / ASP.NET course at Sheridan College.
                        The goal for this application was to teach myself the ASP.NET development
                        environment through building MVC and REST API endpoints.
                    </p>

                    <p>
                        With the frontend, I had the requirement to use the Razor templating engine.
                        I wanted to make things interesting so I took the opportunity to incorporate 
                        progressive web app features. Animal pages are loaded as modals and data is
                        injected into the app using REST endpoints and stored in local storage to 
                        lower requests to the webserver.
                    </p>
                </BreakdownSection>

                <BreakdownSection title="Lessons Learned">

                    <p><b>Making Code Reusable</b></p>

                    <p>
                        When tackling MVC or REST you want to separate your application into components.
                        You can find out quickly that your codebase becomes very complex in a .NET or Java 
                        environment. An example issue is the size of code implemented directly in the controller 
                        endpoints. This causes lots of complexity to gather in a single point in the application and 
                        removes the possibility of reusable code. 
                    </p>

                    <p><u>Solution:</u> Service files for models</p>

                    <p>
                        What a service file can offer is the reuse of code. An example would be a method that 
                        requests a model object from a database by Id. This method can be reused throughout the
                        application and lower the code written by a substantial amount. This also helps maintain 
                        the codebase as there is only one method to write tests for.
                    </p>
                </BreakdownSection>

            </BreakdownGrid>

        </Layout>
    );

}

export default WildlifeReserveCMS;